import React, { useContext, useState } from "react";
import { Button } from "../components/Button/Button";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth, db } from "../services/config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { LanguageContext } from "../utils/LanguageProvider";
import { Link } from "react-router-dom";
import { Loader } from "../components/Loader/Loader";
import { FaFacebook, FaGoogle } from "react-icons/fa";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const userDetailsContext = useContext(UserDetailsContext);
  const { language, t } = useContext(LanguageContext);
  const { updateUserDetails } = useContext(UserDetailsContext);
  const [callingCode, setCallingCode] = useState("+385");
  const [phoneNumber, setPhoneNumber] = useState("");

  const formatPhoneNumber = (number: string): string => {
    const cleanedNumber = number.replace(/\D/g, "");
    return cleanedNumber.replace(/(\d{3})(?=\d)/g, "$1 ");
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError(
        language === "en"
          ? "Please enter both email and password."
          : "Molimo unesite i e-mail i lozinku."
      );
      return;
    }

    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: user.uid,
            email: user.email,
            customClaimRole: userData.customClaimRole || "defaultRole",
            level: userData.level || 0,
            totalPaymentAmount: userData.totalPaymentAmount || 0,
            name: userData.name || "",
            lastName: userData.lastName || "",
            phoneNumber: userData.phoneNumber || "",
            address: userData.address || {},
            callingCode: userData.callingCode || "",
            regularPartOfPhoneNumber: userData.regularPartOfPhoneNumber || "",
          })
        );

        if (userDetailsContext && userDetailsContext.updateUserDetails) {
          await userDetailsContext.updateUserDetails(user);
        }

        console.log("User logged in:", user);
        navigate("/");
      } else {
        console.log("User document not found in Firestore");
        setError(
          language === "en"
            ? "User document not found."
            : "Dokument korisnika nije pronađen."
        );
      }
    } catch (err) {
      setError(
        language === "en"
          ? "Failed to log in. Please check your credentials and try again."
          : "Neuspješno prijavljivanje. Provjerite svoje podatke i pokušajte ponovo."
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialMediaSignIn = async (
    provider: GoogleAuthProvider | FacebookAuthProvider
  ) => {
    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true);

      const user = result.user;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: user.uid,
            email: user.email,
            customClaimRole: userData.customClaimRole || "No subscription",
            level: userData.level || 0,
            totalPaymentAmount: userData.totalPaymentAmount || 0,
            name: userData.name || "",
            lastName: userData.lastName || "",
            phoneNumber: userData.phoneNumber || "",
            address: userData.address || {},
            callingCode: userData.callingCode || "",
            regularPartOfPhoneNumber: userData.regularPartOfPhoneNumber || "",
          })
        );

        if (userDetailsContext && userDetailsContext.updateUserDetails) {
          await userDetailsContext.updateUserDetails(user);
        }

        console.log("User logged in:", user);
        navigate("/");
      } else {
        // console.log("User document not found in Firestore");
        // setError(
        //   language === "en"
        //     ? "User document not found."
        //     : "Dokument korisnika nije pronađen."
        // );
        console.log("test22");

        await setDoc(
          userDocRef,
          {
            name: user.displayName?.split(" ")[0] || "",
            lastName: user.displayName?.split(" ")[1] || "",
            email: user.email,
            phoneNumber: `${callingCode} ${formatPhoneNumber(phoneNumber)}`,
            callingCode,
            regularPartOfPhoneNumber: formatPhoneNumber(phoneNumber),
            level: 0,
            totalPaymentAmount: 0,
          },
          { merge: true }
        );
        console.log("test");

        await updateUserDetails(user);
        navigate("/");
        console.log("navigated ///");
      }
    } catch (err) {
      console.error("Error during social media sign-in:", err.message || err);
      setError(
        language === "en"
          ? "Failed to log in with social media. Please try again."
          : "Neuspješno prijavljivanje putem društvenih mreža. Pokušajte ponovo."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = () => {
    if (error) {
      setError(null);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container max-w-[600px] mx-auto px-5 gap-4 py-6 mb-5 flex flex-col justify-center min-h-[calc(100vh-200px)]">
      <h1 className="text-2xl font-semibold">{t("welcomeBack")}</h1>
      {error && <p className="text-red-500">{error}</p>}
      <input
        className="rounded-md h-12 p-3 border border-emerald-600"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          handleInputChange();
        }}
      />
      <input
        className="rounded-md h-12 p-3 border border-emerald-600"
        type="password"
        placeholder={t("password")}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          handleInputChange();
        }}
      />
      <p>
        <Link to="/forgot-password">
          <span className="font-medium text-emerald-600 hover:text-emerald-700 underline">
            {t("forgotPassword")}
          </span>
        </Link>
      </p>

      <Button
        className="bg-emerald-500 hover:bg-emerald-600 rounded-lg text-white"
        onClick={handleLogin}
      >
        {t("signIn")}
      </Button>
      <p>
        {t("dontHaveAnAccount")}{" "}
        <Link to="/register">
          <span className="font-medium text-emerald-600 hover:text-emerald-700 underline">
            {t("signUp")}
          </span>
        </Link>
      </p>
      <div className="flex items-center justify-center gap-2">
        <span className="flex-grow border-t border-gray-400"></span>
        <p className="mx-2 text-black">{t("or")}</p>
        <span className="flex-grow border-t border-gray-400"></span>
      </div>
      <div className="flex flex-col gap-4">
        <Button
          className="rounded-lg text-white bg-yellow-500 hover:bg-yellow-600"
          onClick={() => handleSocialMediaSignIn(googleProvider)}
        >
          <span className="flex flex-row justify-center gap-2 items-center">
            <FaGoogle />
            {t("signInWithGoogle")}
          </span>
        </Button>
        <Button
          className="rounded-lg text-white bg-blue-500 hover:bg-blue-600"
          onClick={() => handleSocialMediaSignIn(facebookProvider)}
        >
          <span className="flex flex-row justify-center gap-2 items-center">
            <FaFacebook />
            {t("signInWithFacebook")}
          </span>
        </Button>
      </div>
    </div>
  );
};
