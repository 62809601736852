import React, { useContext, useState } from "react";
import { Button } from "../components/Button/Button";
import { LanguageContext } from "../utils/LanguageProvider";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  auth,
  googleProvider,
  facebookProvider,
  db,
} from "../services/config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../types/Countries/Countries";
import { FaFacebook, FaGoogle } from "react-icons/fa";

export const RegisterPage: React.FC = () => {
  const { t } = useContext(LanguageContext);
  const { updateUserDetails } = useContext(UserDetailsContext);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [callingCode, setCallingCode] = useState("+385");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [nameError, setNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

  const formatPhoneNumber = (number: string): string => {
    const cleanedNumber = number.replace(/\D/g, "");
    return cleanedNumber.replace(/(\d{3})(?=\d)/g, "$1 ");
  };

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const isValidName = (value: string) => /^[\p{L}\s]+$/u.test(value);

  const handleRegister = async () => {
    const trimmedName = name.trim();
    const trimmedLastName = lastName.trim();
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();
    const trimmedPhoneNumber = phoneNumber.trim();

    const validName = isValidName(trimmedName);
    const validLastName = isValidName(trimmedLastName);

    setError(null);
    setNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPasswordError(null);
    setPhoneNumberError(null);

    if (!validName) {
      setError(t("nameContainsInvalidCharacters"));
      setNameError(t("nameContainsInvalidCharacters"));
      return;
    }

    if (!validLastName) {
      setError(t("lastNameContainsInvalidCharacters"));
      setLastNameError(t("lastNameContainsInvalidCharacters"));
      return;
    }

    if (!trimmedEmail) {
      setError(t("emailCannotBeEmpty"));
      setEmailError(t("emailCannotBeEmpty"));
      return;
    }

    if (!trimmedPassword) {
      setError(t("passwordCannotBeEmpty"));
      setPasswordError(t("passwordCannotBeEmpty"));
      return;
    }

    if (!trimmedPhoneNumber) {
      setError(t("phoneNumberCannotBeEmpty"));
      setPhoneNumberError(t("phoneNumberCannotBeEmpty"));
      return;
    }

    if (!callingCode) {
      setError(t("pleaseFillAllFields"));
      return;
    }

    if (!checkboxChecked) {
      setError(t("pleaseFillAllFields"));
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        trimmedEmail,
        trimmedPassword
      );
      const user = userCredential.user;

      const capitalizedFirstName = capitalizeFirstLetter(trimmedName);
      const capitalizedLastName = capitalizeFirstLetter(trimmedLastName);

      const userDocRef = doc(db, "users", user.uid);
      setLoading(true);
      await setDoc(userDocRef, {
        name: capitalizedFirstName,
        lastName: capitalizedLastName,
        email: user.email,
        phoneNumber: `${callingCode} ${formatPhoneNumber(trimmedPhoneNumber)}`,
        callingCode,
        regularPartOfPhoneNumber: formatPhoneNumber(trimmedPhoneNumber),
        level: 0,
        totalPaymentAmount: 0,
      });

      await updateUserDetails(user);
      navigate("/");
    } catch (error) {
      console.error("Error creating user:", error);
      setError(t("registrationFailed"));
    } finally {
      setLoading(false);
    }
  };

  const handleSocialMediaSignIn = async (
    provider: GoogleAuthProvider | FacebookAuthProvider
  ) => {
    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true);
      const user = result.user;

      console.log("user:", user);

      console.log(
        "callingCode:",
        `${callingCode} ${formatPhoneNumber(phoneNumber)}`
      );

      console.log("name:", user.displayName?.split(" ")[0] || "");

      console.log("lastName:", user.displayName?.split(" ")[1]);

      const userDocRef = doc(db, "users", user.uid);
      await setDoc(
        userDocRef,
        {
          name: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          email: user.email,
          phoneNumber: `${callingCode} ${formatPhoneNumber(phoneNumber)}`,
          callingCode,
          regularPartOfPhoneNumber: formatPhoneNumber(phoneNumber),
          level: 0,
          totalPaymentAmount: 0,
        },
        { merge: true }
      );
      await updateUserDetails(user);
      navigate("/");
    } catch (error: any) {
      console.error("Error signing in with social media:", error.message);
      if (error.code) {
        console.error("Firebase Error Code:", error.code);
      }
      setError(t("socialSignInFailed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container max-w-[600px] mx-auto px-5 gap-4 py-6 mb-5 flex flex-col justify-center min-h-[calc(100vh-200px)]">
      <h1 className="text-2xl font-semibold">{t("accountCreation")}</h1>
      <div className="flex flex-row gap-3">
        <input
          className={`rounded-md w-[50%] h-12 p-3 border ${
            nameError ? "border-red-500" : "border-emerald-600"
          }`}
          type="text"
          placeholder={t("namePlaceholder")}
          value={name}
          onChange={(e) => {
            const newName = e.target.value.trim();
            setName(newName);
            if (nameError && isValidName(newName)) {
              setNameError(null);
            }
          }}
          disabled={loading}
        />
        <input
          className={`rounded-md w-[50%] h-12 p-3 border ${
            lastNameError ? "border-red-500" : "border-emerald-600"
          }`}
          type="text"
          placeholder={t("lastNamePlaceholder")}
          value={lastName}
          onChange={(e) => {
            const newLastName = e.target.value.trim();
            setLastName(newLastName);
            if (lastNameError && isValidName(newLastName)) {
              setLastNameError(null);
            }
          }}
          disabled={loading}
        />
      </div>
      <input
        className={`rounded-md h-12 p-3 border ${
          emailError && error ? "border-red-500" : "border-emerald-600"
        }`}
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => {
          const newEmail = e.target.value.trim();
          setEmail(newEmail);
          if (emailError && newEmail) {
            setEmailError(null);
          }
        }}
        disabled={loading}
      />
      <input
        className={`rounded-md h-12 p-3 border ${
          passwordError ? "border-red-500" : "border-emerald-600"
        }`}
        type="password"
        placeholder={t("password")}
        value={password}
        onChange={(e) => {
          const newPassword = e.target.value.trim();
          setPassword(newPassword);
          if (passwordError && newPassword) {
            setPasswordError(null);
          }
        }}
        disabled={loading}
      />
      <div className="flex flex-row gap-3">
        <select
          className="rounded-md w-[30%] h-12 p-3 border border-emerald-600"
          value={callingCode}
          onChange={(e) => setCallingCode(e.target.value)}
          disabled={loading}
        >
          {countries.map((country, index) => (
            <option key={index} value={country.callingCode}>
              {country.cca2} ({country.callingCode})
            </option>
          ))}
        </select>
        <input
          className={`rounded-md w-[70%] h-12 p-3 border ${
            phoneNumberError ? "border-red-500" : "border-emerald-600"
          }`}
          type="number"
          placeholder={t("mobileNumber")}
          value={phoneNumber}
          onChange={(e) => {
            const newPhoneNumber = e.target.value.trim();
            setPhoneNumber(newPhoneNumber);
            if (phoneNumberError && newPhoneNumber) {
              setPhoneNumberError(null);
            }
          }}
          disabled={loading}
        />
      </div>
      <div className="flex flex-row gap-3 px-2">
        <input
          className={`w-8 h-8 my-auto ${
            !checkboxChecked ? "border-red-500" : "border-emerald-600"
          }`}
          type="checkbox"
          checked={checkboxChecked}
          onChange={(e) => setCheckboxChecked(e.target.checked)}
          disabled={loading}
        />
        <p className="text-left content-center">
          {t("byRegisteringText")}
          <a
            href="/privacy"
            className="font-medium underline hover:text-gray-400 cursor-pointer decoration-1"
          >
            {t("withPrivacy")}
          </a>
          <span>{" " + t("and") + " "}</span>
          <a
            href="/terms"
            className="font-medium underline hover:text-gray-400 cursor-pointer decoration-1"
          >
            {t("withLegal")}
          </a>
        </p>
      </div>
      {error && <p className="text-red-500">{error}</p>}

      <Button
        className="text-white bg-emerald-600 hover:bg-emerald-700 rounded-lg"
        onClick={handleRegister}
        disabled={loading}
      >
        {loading ? t("loading") : t("signUp")}
      </Button>
      <p>
        {t("doYouHaveAccount")}{" "}
        <Link to="/login">
          <span className="font-medium underline text-emerald-600 hover:text-emerald-700">
            {t("signIn")}
          </span>
        </Link>
      </p>
      <div className="flex items-center justify-center gap-2">
        <span className="flex-grow border-t border-gray-400"></span>
        <p className="text-gray-400">{t("or")}</p>
        <span className="flex-grow border-t border-gray-400"></span>
      </div>
      <Button
        className="flex items-center justify-center text-white bg-yellow-500 hover:bg-yellow-600 rounded-lg"
        onClick={() => handleSocialMediaSignIn(googleProvider)}
        disabled={loading}
      >
        <span className="flex flex-row justify-center gap-2 items-center">
          <FaGoogle />
          {t("signUpWithGoogle")}
        </span>
      </Button>
      <Button
        className="flex items-center justify-center text-white bg-blue-500 hover:bg-blue-600 rounded-lg"
        onClick={() => handleSocialMediaSignIn(facebookProvider)}
        disabled={loading}
      >
        <span className="flex flex-row justify-center gap-2 items-center">
          <FaFacebook />
          {t("signUpWithFacebook")}
        </span>
      </Button>
    </div>
  );
};
