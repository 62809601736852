import React, { useContext } from "react";
import { Button } from "../Button/Button";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../utils/LanguageProvider";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";
import { useGetActiveSubscription } from "../../services";

export const HomeSelector: React.FC = () => {
  const { t } = useContext(LanguageContext);
  const { userDetails } = useContext(UserDetailsContext);

  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  const isNotSubscribed = activeSubscription?.role;

  console.log(isNotSubscribed);
  return (
    <div className="flex flex-col md:flex-row md:justify-evenly my-8 gap-6">
      <div
        className={`flex justify-between md:justify-center text-center basis-1/2 gap-5 px-4`}
      >
        <h2 className="my-auto font-bold">
          {!isNotSubscribed || !userDetails
            ? t("chooseSubscription")
            : activeSubscription?.role + " " + t("subscription")}
        </h2>
        {!isNotSubscribed ? (
          <Link to={`/subscription`}>
            <Button className="md:w-full min-w-[160px] rounded-3xl text-emerald-500 w-48 bg-white hover:bg-gray-300">
              {t("choose")}
            </Button>
          </Link>
        ) : (
          <a
            target="_blank"
            // href="https://billing.stripe.com/p/login/9AQcQk3Lm3Rn4Te288"
            href="https://billing.stripe.com/p/login/test_cN2bLr6emdngeyc288"
            rel="noreferrer"
          >
            <Button className="md:w-full min-w-[160px] rounded-3xl text-emerald-500 w-48 bg-white hover:bg-gray-300">
              {t("change")}
            </Button>
          </a>
        )}
      </div>
      <div className="flex text-center basis-1/2 justify-between md:justify-center md:gap-6 px-4">
        <h2 className="my-auto font-bold">{t("oneTimePayment")}</h2>
        <Link to="/one-time-payments">
          <Button
            disabled={!isNotSubscribed}
            className={`${
              !isNotSubscribed
                ? "bg-gray-300 text-gray-400"
                : "bg-white hover:bg-gray-300"
            } min-w-[160px] text-emerald-500 rounded-3xl`}
          >
            {t("enter")}
          </Button>
        </Link>
      </div>
    </div>
  );
};
